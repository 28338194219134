import { ICoreContext, getUrlSync } from '@msdyn365-commerce/core';
import { getAttributeStringOrDefault, getAttributesLocale } from './attributes';
import { getMostUsedAttributeNameIndex, sortAttributes, sortAttributesByGroupOrder } from '.';
import { AttributeValue } from '@msdyn365-commerce/retail-proxy';

export interface IAttributeGroupMapAttribute {
    name: string | string[];
    label?: string;
    hideIfEmpty?: boolean;
    transform?: (value: string) => string;
    transformName?: (key: string) => string;
}

export interface IAttributeGroupMap {
    type: AttributeGroupType;
    group: string;
    /** Sets the mode for how to handle the 'attributes':
     * - 'include': Only include the attributes defined in 'attributes'.
     * - 'transform': Include all attributes and apply transformations defined in 'attributes' on matching attributes.
     */
    attributesMode: 'include' | 'transform';
    attributes?: (string | IAttributeGroupMapAttribute)[];
    transform?: (value: string) => string;
    defaultValue?: string;
    nullValues?: string[];
    /** Sets global 'hideIfEmpty' for all attributes in this group. Only used if 'attributes' is not defined or list of strings. */
    hideIfEmpty?: boolean;
    /** The attribute to use as source. If set this will look for the attributes inside this attribute. */
    attributeSource?: string;
    attributeSourceSet?: { source: string, value: string }[];
    exclude?: string[];
}

export type AttributeGroupType = 'default';
export type ModelVersion = '1.0' | '2.0' | '3.0';

export interface IParsedAttributeMap {
    type: AttributeGroupType;
    name: string;
    attributes: IParsedAttribute[];
    modelVersion?: ModelVersion;
}

export interface IParsedAttribute {
    name: string;
    value: string;
}

export const replaceCommaWithDot = (value: string): string => {
    // Shouldn't need to replace anything if both are present
    if (value.includes(',') && value.includes('.')) {
        return value.replace(' ', '');
    }
    // Only replace the last occurance of a comma with a dot
    const lastCommaIndex = value.lastIndexOf(',');
    if (lastCommaIndex !== -1) {
        return `${value.substring(0, lastCommaIndex)}.${value.substring(lastCommaIndex + 1)}`.replace(' ', '');
    }
    // If no comma is present, return the value as is
    return value.replace(' ', '');
    // return value.replace(',', '.').replace(' ', '');
};
export const padValue = (value: string): string => {
    return Number(value.replace(',', '')).toFixed(2).toString();
};
export const addCelsiusSign = (value: string): string => {
    return value ? `${value}°` : value;
};
export const addDashPrefix = (key: string): string => {
    return ` - ${key}`;
};

function findAttribute(attributeName: string): (attribute: string | IAttributeGroupMapAttribute) => boolean {
    return (attribute: string | IAttributeGroupMapAttribute) => {
        if (typeof attribute === 'string') {
            return attribute === attributeName;
        } else {
            return attribute.name === attributeName;
        }
    };
}

export function parseAttributesMap(context: ICoreContext, attributes: AttributeValue[] | undefined, attributesMap?: IAttributeGroupMap[]): IParsedAttributeMap[] {
    if (!attributesMap) {
        attributesMap = getAttributesMap(context);
    }

    const attributesLocale = getAttributesLocale(context.request.locale);
    const groups: {
        [key: string]: AttributeValue[];
    } = {};

    attributes!.sort(sortAttributesByGroupOrder).map((product: AttributeValue, index) => {
        const attrFind = product.ExtensionProperties?.find((e) => {
            return e.Key === 'ATTRGROUPNAME';
        });
        if(attrFind && attrFind.Value && attrFind.Value.StringValue){
            const key = attrFind.Value.StringValue.trim();
            if (groups[key]) {
                groups[key].push(product);
            } else {
                groups[key] = [];
                groups[key].push(product);
            }
        }
    });

    const parsed: IParsedAttributeMap[] = attributesMap.map((attributeGroup) => {
        const mostCommonNameIndex = getMostUsedAttributeNameIndex(attributes, attributeGroup, attributesLocale.notFound);

        if (!attributeGroup.attributes && !groups[attributeGroup.group]) {
            return {
                type: attributeGroup.type,
                name: attributeGroup.group,
                attributes: []
            };
        }

        let modelVersion: ModelVersion = '1.0';
        let attributesToUse: AttributeValue[] | undefined;
        const attributeSource = attributeGroup.attributeSource
            ? attributes!.find((attribute: AttributeValue) => attribute.Name === attributeGroup.attributeSource)
            : undefined;
        const shouldUseAttributeSet = attributeGroup.attributeSourceSet?.some((source) => {
            return attributes?.find((attribute: AttributeValue) => attribute.Name === source.source)?.TextValue;
        });

        if (shouldUseAttributeSet) {
            modelVersion = '3.0';
            attributesToUse = [];

            attributeGroup.attributeSourceSet!.forEach((source) => {
                const attribute = attributes?.find((attribute: AttributeValue) => attribute.Name === source.source);

                if (!attribute || !attribute.TextValue) {
                    return;
                }

                attributesToUse!.push(...attribute.TextValue.split('|').map((attribute) => {
                    return {
                        Name: attribute,
                        DataTypeValue: 5,
                        TextValue: source.value,
                        ExtensionProperties: [
                            {
                                Key: 'ATTRORDER',
                                Value: {
                                    DecimalValue: (attributeGroup.attributes?.findIndex((attr) => {
                                        if (typeof attr === 'string') {
                                            return attr === attribute;
                                        } else {
                                            return attr.name === attribute;
                                        }
                                    }) ?? -1) + 1
                                }
                            }
                        ]
                    } as AttributeValue;
                }));
            });
        } else if (attributeGroup.attributeSource && attributeSource?.TextValue) {
            modelVersion = '2.0';
            attributesToUse = attributeSource?.TextValue?.split('|').map((attribute) => {
                return {
                    Name: attribute.split(':')[0],
                    DataTypeValue: 5,
                    TextValue: attribute.split(':')[1]
                } as AttributeValue;
            });
        } else {
            if (attributeGroup.attributesMode === 'include' && attributeGroup.attributes) {
                // Only get the attributes defined in the 'attributes' list
                const includes = attributeGroup.attributes.map(attr => {
                    if (typeof attr === 'string') {
                        return attr;
                    } else {
                        if (typeof attr.name === 'string') {
                            return attr.name;
                        } else {
                            return attr.name[mostCommonNameIndex];
                        }
                    }
                });
                attributesToUse = attributes?.filter((attribute: AttributeValue) => includes.includes(attribute.Name || ''));
            } else {
                // Get all attributes in this group and 'attributes' is a list of transformations to apply to matching attributes
                attributesToUse = groups[attributeGroup.group];
            }
        }

        const attrsNew = attributesToUse?.sort(sortAttributes).map((attribute) => {
            if (!attribute.Name) {
                throw new Error(`Attribute name is missing.`);
            }

            if (attributeGroup.exclude && attributeGroup.exclude.includes(attribute.Name)) {
                return undefined;
            }

            let name = attribute.Name;
            let value = getAttributeStringOrDefault(attributesToUse,
                attribute.Name,
                attributeGroup.defaultValue);

            const findAttributeFromAttributes = attributeGroup.attributes?.find(findAttribute(attribute.Name));

            if (typeof findAttributeFromAttributes === 'object') {
                if (findAttributeFromAttributes.transformName) {
                    name = findAttributeFromAttributes.transformName(attribute.Name);
                }

                if (value && findAttributeFromAttributes.transform) {
                    value = findAttributeFromAttributes.transform(value);
                }

                if (value && attributeGroup.transform) {
                    value = attributeGroup.transform(value);
                }

                if (findAttributeFromAttributes.hideIfEmpty && !value) {
                    return undefined;
                }

                if (findAttributeFromAttributes.hideIfEmpty !== false && attributeGroup.hideIfEmpty && !value) {
                    return undefined;
                }
            } else {
                if (value && attributeGroup.transform) {
                    value = attributeGroup.transform(value);
                }

                if (attributeGroup.hideIfEmpty && !value) {
                    return undefined;
                }
            }

            return {
                name,
                value: value || attributesLocale.notFound
            };
        });

        return {
            type: attributeGroup.type,
            name: attributeGroup.group,
            attributes: (attrsNew || []).filter(x => x).map((attribute) => {
                const { name, value } = attribute || {};

                return {
                    name: name as string,
                    value: value as string
                };
            }),
            modelVersion
        };
    });

    return parsed;
}

export function getAttributesMap(context: ICoreContext): IAttributeGroupMap[] {
    const attributesLocale = getAttributesLocale(context.request.locale);

    return [
        {
            type: 'default',
            group: attributesLocale.groups.aboutProduct,
            hideIfEmpty: true,
            attributesMode: 'transform',
            attributes: [
                {
                    name: attributesLocale.country,
                    transform: (value) => {
                        const searchURLBase = getUrlSync('search', context.actionContext);
                        const separator = searchURLBase!.includes('?') ? '&' : '?';

                        return value.split('|').map((v) => {
                            const searchURL = `${searchURLBase}${separator}q=${encodeURIComponent(v)}`;
                            return `<a href='${searchURL}'>${v}</a>`;
                        }).join('<span>, </span>');
                    }
                },
                {
                    name: attributesLocale.alcoholPercent,
                    transform: (value) => `${value}%`
                },
                {
                    name: attributesLocale.volumeCl,
                    transform: (value) => `${value} cl`
                }
            ]
        }
    ];
}
